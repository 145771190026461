.newForm {
  width: 60%;
  margin: 3rem auto;
  box-shadow: 2px 4px 10px 1px rgb(0 0 0 / 30%);
  padding: 20px;

  .form-group {
    // margin: 0px;
    display: flex;
    gap: 10px;
  }
}

.errorMsgWrapper {
  color: #e60023;
  background-color: #fce6e8 !important;
  padding: 10px 25px;
  margin-bottom: 30px;
  // margin-top: -20px;
  border-radius: 3px;
}
.errorMsg {
  color: #e60023;
  font-size: 0.8rem;
  padding: 8px 0;
}

.color1 {
  background-color: #fce6e8 !important;
}
