.single{
    display: flex;
    .singleContainer{
        flex: 6;


        .top{
            padding: 20px;
            display: flex;
            gap:20px;

            .left{
                flex:1 1;
                padding: 20px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
                box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
                position: relative;
                .editButton{
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    font-size: 14px;
                    color: rgb(16, 189, 108);
                    cursor: pointer;
                    background: rgba(17, 216, 123, 0.185);
                    padding: 5px;
                    border-radius: 0px 0px 0px 5px;
                }
                .item{
                    display: flex;
                    gap: 20px;

                    .itemImage{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .details{
                        .itemTitle{
                            margin-bottom: 10px;
                            color: #555;
                        }

                        .detailItem{
                            margin-bottom: 10px;
                            font-size: 14px;
                        }

                        .itemKey{
                            font-weight: bold;
                            color: gray;
                            margin-right: 8px;
                            
                        }

                        .itemValue{
                            font-weight: 300;
                        }
                    }
                }
            }

            .right{
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
                box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
            }
        }
        
    .bottom{
        padding: 20px;
        margin: 20px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
        box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
        display: block;
    }
    }
    
}


.title{
    font-size: 15px;
    color: lightgray;
    margin-bottom: 20px;
}