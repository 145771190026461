.new {
  display: flex;

  .newContainer {
    flex: 6;
    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.3);
      padding: 10px;
      margin: 20px;
      display: flex;
    }

    h1 {
      color: lightgray;
      font-size: 20px;
    }
    .left {
      flex: 1;
      text-align: center;
      img {
        width: 350px;
        height: 179px;
        /* border-radius: 100%; */
        object-fit: cover;
      }
    }
    .right {
      flex: 2;
      form {
        .divid__trip {
          display: flex;
          gap: 10px;
          //   flex-wrap: wrap;
          //   justify-content: space-around;
        }
      }

      .formInput {
        width: 100%;
      }

      label {
        display: flex;
        align-items: center;
        gap: 19px;
        cursor: pointer;
        border: thin solid black;
        padding: 20px;
        width: 70px;
        margin: 1rem;

        .icon {
          cursor: pointer;
        }
      }
      input {
        width: 100%;
        padding: 5px;
        border: none;
        border-bottom: 1px solid gray;
        outline: none;
      }

      button {
        width: 150px;
        padding: 10px;
        border: none;
        background-color: teal;
        color: white;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
      }
      .seenTrip {
        background-color: aliceblue;
        border: 1px solid grayscale($color: #000000);
        color: rgb(56, 52, 52);
        display: flex;
        align-items: center;
        padding: 0px 0px 0px 10px;
      }
    }
  }
}
