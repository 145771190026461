.newTrip{
    display: flex;

    .newContainer{
        flex: 6;
        .top{
            
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
        box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
            padding: 10px;
            margin: 20px;
            display: flex;
        }
        
        h1{
            color: lightgray;
            font-size: 20px;
        }

        .bottom {
            display: flex;
            gap: 10px;
            // justify-content: center;
            align-items: flex-start;
            
            .right {
                min-height: 200px;
                flex-basis: 20%;
                // flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
                box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
                    padding: 10px;
                    margin: 20px;

                    .content1 {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        padding: 1rem;

                        .sidebar-row {
                            cursor: pointer;
                            
                            &.active-side {
                            color: #e3e3e3;
                            padding: 0.6rem 0.2rem 0.6rem 0.2rem;
                            background: #72383888;
                            }
                        }
                    }
            }
            .left {
                min-height: 200px;
                display: flex;
                flex-basis: 80%;
                // flex: 2;

                    .center {
                        width: 50%;                   
                        flex: 1;
                    }
                    .left2 {
                        width: 50%;
                        align-self: flex-start;
                        flex: 1;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
                        box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
                            padding: 10px;
                            margin: 20px;
                    }
            }
            
        }
        // .left{
        //     flex:1;
        //     text-align: center;
            
        // }
        // .center{
        //     flex: 1;
        //     display: flex;
        //     flex-direction: column;
        //     form{
        //         display: flex;
        //         flex-wrap: wrap;
        //         gap: 30px; 
        //         justify-content: space-around;
        //     }

        //     .formInput{
        //         width: 100%;;
        //     }

        //     label{
        //         display: flex;
        //         align-items: center;
        //         gap: 19px;
        //         cursor: pointer;
        //         // border: thin solid black;
        //         // padding: 20px;
        //         // width: 70px;
        //         margin: 1rem;


        //         .icon{
        //             cursor: pointer;
        //         }
        //     }
        //     input{
        //         width: 100%;
        //         padding: 5px;
        //         border: none;
        //         border-bottom: 1px solid gray;
        //         outline: none;
        //     }

        //     button{
        //         width: 150px;
        //         padding: 10px;
        //         border: none;
        //         background-color: teal;
        //         color: white;
        //         font-weight: bold;
        //         cursor: pointer;
        //         margin-top: 10px;
        //     }

        // }

        // .right{
        //     flex: 1;
        //     display: flex;
        //     justify-content: center;
        //     // align-items: center;
        // }

    }
}