.user__list {
  display: flex;
  width: 100%;
  .ListContainer {
    flex-basis: 85%;
    padding: 10px 40px 10px 10px;

    // flex: 6 1;
  }
}
