
    .navbar{
        height: 50px;
        // border: .5px solid rgb(204, 204, 204);
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #555;
        padding: 1rem 0.5rem;
    
        .wrapper{
            display: flex;
            align-items: center;
            padding: 20px;
            justify-content: space-between;
            width: 100%;
    
            .search{
                display: flex;
                align-items: center;
                border: 0.5px solid lightgray;
                padding: 3px;
    
                input{
                    border: none;
                    outline: none;
                    background-color: transparent;
    
                    &::placeholder{
                        font-size: 12px;
                    }
                }
            }
        }
        .items, .items-with-icons{
            display: flex;
            align-items: center;
            
            .item{
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;
    
                .icon{
                    font-size: 25px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
                    box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
                    cursor: pointer;
                }
    
                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
    
                }
            }
        }
    }
    

    
        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (max-width: 600px) {
            .navbar{
                border-bottom: 1px solid grey;
                border-right: none;
                border-left: none;
                border-top: none;
                .items-with-icons{
                    display: none;
                }
            }
        }
    
        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (max-width: 768px) {
            
        }
    
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (max-width: 992px) {
            
        }
    
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (max-width: 1200px) {
            
        }