.table {
  .cellWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .status {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    &.Seen {
      padding-right: 10px;
      padding-left: 10px;
      color: green;
      background-color: rgba(0, 128, 0, 0.466);
    }
    &.Pending {
      padding-right: 5px;
      padding-left: 5px;
      color: goldenrod;
      background-color: rgba(218, 165, 32, 0.514);
    }
    // &.Declined{
    //     color: red;
    //     background-color: rgba(255, 0, 0, 0.534);
    // }
  }
}

.show__btn {
  display: block !important;
}

.display__modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.336);
  z-index: 99999;
}

.modal__body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 35%;
  height: 35%;
  text-align: center;
  padding: 30px;

  .btn_no1 {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-weight: 800;
    opacity: 1;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.527);
    border-radius: 200%;
    padding: 0.2rem 0.4rem;
  }

  .body__cont {
    margin-bottom: 1rem;
  }

  .btn_modal {
    button {
      padding: 10px 18px;
      font-weight: 800;
      opacity: 1;
      cursor: pointer;
      border: 2px solid rgba(0, 0, 0, 0.527);
    }

    .btn_yes {
      color: #fff;
      background: rgba(255, 68, 0, 0.712);
      margin: 1.5rem;
    }
  }
}
