.widget2{
    display: flex;
    // width: 400px;
    flex: 1;
    justify-content: space-between;
    padding: 12px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
    box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
    border-radius: 10px;
    height: 120px;

    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: gray;
        }

        .counter{
            font-size: 28px;
            font-weight: 30%;
        }

        .link{
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive{
                color: green
            }

            &.negative{
                color: red;
            }
        }

        .icon{
            font-size: 18px !important;
            padding: 5px;
            border-radius: 10%;
            align-self: end;
            
        }
    }

}

// .widget3{
//     display: flex;
//     // width: 400px;
//     flex: 1;
//     justify-content: space-between;
//     padding: 12px;
//     -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
//     box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
//     border-radius: 10px;
//     height: 100px;}