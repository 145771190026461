.chart{
    flex:4;    
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
    box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
    padding: 10px;
    color: gray;
    .title{
        margin-bottom: 20px;
        color: #000;
    }

    .chatGrid{
        stroke: rgba(211, 211, 211, 0.808);
        
    }
}