.home{
    display: flex;

    .homeContainer{
        flex: 6;
        .widgets, .charts{
            display: flex;
            padding: 20px;
            gap: 20px
        }

        .charts{
            padding: 5px 20px;
        }

        .listContainer{        
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
            box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
            padding: 20px;
            margin: 20px;

            .listTitle{
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}

.pagination1{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    gap: 1.5rem;
    color: blue;
    font-size: 1.3rem;
    cursor: pointer;
}
.page-num{
    
}


.active{
    color: grey;  
}

        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .home{
                .widgets, .charts{
                    flex-direction: column;
                }
            }
        }
    
        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px) {
            
        }
    
        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px) {
            
        }
    
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) {
            
        }
    
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
            
        }