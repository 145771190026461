.sidebar{
    flex: 1;
    border-right: .5px solid rgb(204, 204, 204);
    min-height: 100vh;
    background-color: white;
    .top{
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .cancelIcon{
            display: none;
        }
    }

    .logo{
        // font-size: 20px;
        // font-weight: bold;
        color: rgb(91, 91, 247);
        text-align: center;
    }

    hr{
        height: 0px;
        border: .5px solid rgb(204, 204, 204);
    }

    .center{
        padding-left: 10px;
        ul{
            list-style-type: none;
            padding: 0px;
        }
    }

    .title{
        font-size: 10PX;
        font-weight: bold;
        color: #999;
        margin-top: 15px;;
        margin-bottom: 5px;
    }

    li{
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        .icon{
            font-size: 18px;
            color: #7451f8;
        }

        span{
            font-size: 13px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
        }
        
        &:hover{
            background-color: #ece8ff;
        }

    }
}
.bottom{
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption{
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #7451f8;;
        cursor: pointer;

        &:nth-child(1){
            background-color: whitesmoke;
        }

        &:nth-child(2){
            background-color: #333;
        }

        &:nth-child(3){
            background-color: darkblue;
        }
    }

}


        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .home, .list, .new{
                display: block !important;
                .sidebar{
                    position: fixed;
                    top: 0px;
                    width: 55%;
                    z-index: 1000;
                    .cancelIcon{
                        display: block;
                    }
                    .top{
                        justify-content: space-between;
                        padding: 1px 20px;

                        .cancelIcon{
                            color: red;
                            cursor: pointer;
                        }
                    }

                    &.deactivate{
                        left: -250px;
                        transition: left 1s ease-in;
                    }
                    &.activate{
                       left: 0px;
                        transition: left 1s ease-out;
                    }
                }
            }
        }
    
        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (max-width: 600px) {
            
        }
    
        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (max-width: 768px) {
            
        }
    
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (max-width: 992px) {
            
        }
    
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
            
        }