.center1 {
    width: 100%;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
    box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
        padding: 10px;
        margin: 20px;

}


.left2 {
            
           
    .content--View {
       display: flex;
       width: 100%;
       justify-content: space-between;
       align-items: center;
       padding: 1rem 1rem;
       background: rgba(167, 138, 72, 0.1);
      border: 1px solid #F8F8F8;
      border-radius: 10px;


       button {
        width: 79px;
        height: 28.11px;    
        background: rgba(231, 59, 21, 0.1);
        border: none;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #E73B15;
        cursor: pointer;
       }
       span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #A78A48;
        cursor: pointer;
       }
   }
   
}

.ine {
    width: 60%;
    padding: 1rem 1rem;
    margin: 0px 10px 0px 0px;
}