.list2 {
  display: flex;
  width: 100%;

  .ListContainer {
    padding: 10px 25px 10px 10px;
    flex-basis: 85%;

    // flex: 6 1;
  }
}
