.cont__login {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../public/img/tkeBG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 100vw;
  object-fit: fill;
  min-height: 100vh;
  position: relative;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  // margin: 15% auto;
  text-align: center;
  background-color: #ffffffd0;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.3);
  padding: 40px;

  h3 {
    color: blue;
    margin: 1rem;
    font-size: 2rem;
  }
  .format {
    width: 80%;
    margin: 0px auto;

    Button {
      margin-left: 40px;
    }
  }
}
