.dropdownShow {
    position: relative;

    .dropdownMenu{
        position: absolute;
        top: 20px;
        left: -25px;
        min-width: 30px;
        min-height: 30px;
        background-color: aliceblue;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,.3);
        box-shadow: 2px 4px 10px 1px rgba(0,0, 0,.3);
        padding: 0.5rem 0.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 1000
    }
    .dropdownItem{
        color: #000;
    text-decoration: none;
    cursor: pointer;
    padding: 0.2rem;

    &:hover {
        background-color: blueviolet;
        color: #fff;
    }
    }
}

#show{
    display: none;
}